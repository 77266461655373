<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="父选项" prop="parentContent" v-if="form.parentContent">
        <a-input type="textarea" disabled v-model="form.parentContent" />
      </a-form-model-item>
      <a-form-model-item label="选项内容" prop="content" >
        <a-input type="textarea" :maxLength="200" v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" >
        <a-input-number style="width: 300px" :min="0" v-model="form.sort" placeholder="请输入排序" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLetterOption, addLetterOption, updateLetterOption } from '@/api/cover/letterOption'
import Editor from '@/components/Editor'

export default {
  name: 'ChildCreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        coverLetterId: null,

        parentId: null,
        parentContent:null,

        content: null,

        sort: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        coverLetterId: [
          { required: true, message: 'cover letter.id不能为空', trigger: 'blur' }
        ],
        pId: [
          { required: true, message: '上级ID不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '选项内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        coverLetterId: null,
        parentId: null,
        parentContent:null,
        content: null,
        sort: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (pid,coverLetterId,parentContent) {
      console.log(pid,coverLetterId,parentContent)
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form.parentId = pid
      this.form.coverLetterId = coverLetterId
      this.form.parentContent = parentContent
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLetterOption({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateLetterOption(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addLetterOption(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
