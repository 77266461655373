<template>
  <page-header-wrapper>
    <a-card :bordered="false">
        <span slot="title" class="card-title">
        <a-button size="small" @click="$router.go(-1)" style="margin-right: 15px;">
          <a-icon type="left" />返回
        </a-button>
        <span>{{this.coverLetter}}</span>
      </span>
      <div class="table-operations">
        <a-button type="primary" @click="handleAdd()" >
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" >
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete">
          <a-icon type="delete" />删除
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :expandedRowKeys="expandedKeys"
        @expand="onExpandCurrent"
        :bordered="tableBordered"
      >
         <span slot="expandedRowRender" slot-scope="text">
          <child-letter-option ref="childOption" :pid="text.id" :coverLetterId="text.coverLetterId" :parent-content="text.content"/>
        </span>

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record)">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageLetterOption,listLetterOption, delLetterOption } from '@/api/cover/letterOption'
import CreateForm from './modules/CreateForm'
import ChildLetterOption from './childIndex'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'LetterOption',
  components: {
    CreateForm,ChildLetterOption
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      coverLetterId:null,
      coverLetter:null,
      selectedRowKeys: [],
      selectedRows: [],
      childSelectedRowKeys: [],
      childSelectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      expandedKeys: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        coverLetterId: null,
        parentId: null,
        content: null,
        sort: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '选项内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '排序',
          dataIndex: 'sort',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
    }
  },
  filters: {
  },
  created () {
    this.coverLetterId = this.$route.query.coverLetterId
    this.coverLetter = this.$route.query.coverLetter
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {

    getList () {
      this.loading = true
      this.queryParam.coverLetterId = this.coverLetterId
      this.queryParam.parentId = 0
     pageLetterOption(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handleAdd(){
      this.$refs.createForm.handleAdd(this.coverLetterId,this.coverLetter)
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        coverLetterId: undefined,
        pId: undefined,
        content: undefined,
        sort: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },

    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delLetterOption(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    onExpandCurrent(expandedKeys, row) {
      if (expandedKeys) {
        this.expandedKeys = [row.id]
      } else {
        this.expandedKeys = []
      }
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cover/letterOption/export', {
            ...that.queryParam
          }, `cover letter选项_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
